import logo from './logo.svg';
import './App.css';
import React, { Component }  from 'react';
// Import react-foundation components
import { Grid, Cell,Menu,MenuItem } from "react-foundation";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Dolores Street Party
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          October 7, 2023 - Save the Date!
        </a>
      </header>
    </div>
  );
}

export default App;
